/* eslint-disable indent */
import { useCallback, useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";

import { FunnelData, Pet } from "@/types/pet";
import { useFunnelStore } from "@/store/index";
import { useUserDataStore } from "@/store/UserDataStore";
import { usePetFactory } from "@/hooks/usePetFactory";
import { track } from "@/helpers/analytics";

type Props = {
	onComplete: (iteration: number) => void;
	goToFirstStep?: () => void;
};

export const useFunnelStepForm = ({ onComplete, goToFirstStep }: Props) => {
	const hasSetInitalLoad = useRef<boolean>(false);

	const [isWorking, setIsWorking] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [user, setUserPets, setActivePetObjectValidity] = useUserDataStore(
		(state) => [state.user, state.setUserPets, state.setActivePetObjectValidity]
	);

	const [data, email, firstName, marketingOptIn] = useFunnelStore((state) => [
		state.data,
		state.email,
		state.firstName,
		state.marketingOptIn,
	]);

	const { isReady, query } = useRouter();

	const { createPetFromStore, isFunnelDataComplete, storeEmail } =
		usePetFactory();

	useEffect(() => {
		if (hasSetInitalLoad.current) {
			return;
		}

		if (isReady) {
			setIsLoaded(true);
			hasSetInitalLoad.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReady]);

	const completeForm = useCallback(
		async (
			newEmail?: string,
			newFirst?: string,
			newOptIn?: boolean,
			newFunnelData?: FunnelData[]
		) => {
			if (!isFunnelDataComplete()) {
				console.log("Funnel data is not complete");
				console.log(data);

				if (data.length < 1) {
					console.error("Pet funnel data was not complete");
					goToFirstStep?.();

					return;
				}
			}

			const startTime = Date.now();

			setIsWorking(true);

			const transformedPetDetails = await createPetFromStore(newFunnelData);

			if (!transformedPetDetails) {
				track("Funnel missing data", {
					step: "Transformed pet details",
					email: email,
					transformedPetDetails: transformedPetDetails,
				});
				setIsWorking(false);

				return;
			}

			const newPets = transformedPetDetails.reduce(
				(petList, pet, index) => {
					const currentPet = user.pets
						? Object.keys(user.pets).find(
								(id) =>
									user.pets?.[id].name?.toLowerCase() ===
									pet.name?.toLowerCase()
							)
						: null;

					const petId = currentPet ?? pet.petId;

					const currentPetInfo =
						currentPet && user.pets?.[currentPet] ? user.pets[currentPet] : {};

					return {
						...petList,
						[petId]: {
							...currentPetInfo,
							...pet,
							index,
						},
					};
				},
				{} as Record<string, Pet>
			);

			setUserPets(newPets, parseInt((query.iteration as string) || "1"));
			setActivePetObjectValidity(true);

			storeEmail(
				Object.values(newPets),
				newEmail || email,
				newFirst || firstName,
				newOptIn ?? marketingOptIn
			);

			const endTime = Date.now();

			const timeTaken = endTime - startTime;

			// SET TIME LIMITS
			const timeCheck = 6000;

			if (timeTaken >= timeCheck) {
				onComplete(query.iteration ? parseInt(query.iteration as string) : 1);
				setIsWorking(false);

				return;
			}

			setTimeout(() => {
				onComplete(query.iteration ? parseInt(query.iteration as string) : 1);
			}, timeCheck - timeTaken);
		},
		[
			isFunnelDataComplete,
			createPetFromStore,
			setUserPets,
			query.iteration,
			setActivePetObjectValidity,
			storeEmail,
			email,
			firstName,
			marketingOptIn,
			data,
			goToFirstStep,
			user.pets,
			onComplete,
		]
	);

	return {
		isLoaded,
		isWorking,
		completeForm,
	};
};
